@import "../../assets/style/mixin";


.advantages-sec{
  background: #716F70;
  padding: 80px 0;
  margin-top: 180px;
  @include screen-max-width-991{
    margin-top: 120px;
  }
  @include screen-max-width-576{
    margin-top: 80px;
    padding: 60px 0;
  }
  h2{
    color: #ffffff;
  }
  .advantages-flex{
    gap: 50px 30px;
    margin-top: 50px;
    justify-content: space-between;
    @include screen-max-width-767{
      gap: 40px;
    }
    .advantages-info{
      width: 48%;
      @include screen-max-width-991{
        width: 100%;
      }
      h4{
          font-size: 24px;
          line-height: 30px;
          color: #ffffff;
        @include screen-max-width-991{
          font-size: 22px;
        }
        @include screen-max-width-576{
          font-size: 20px;
        }
      }
      p{
        font-size: 20px;
        line-height: 26px;
        margin-top: 15px;
        color: #ffffff;
        @include screen-max-width-767{
          font-size: 18px;
        }
      }
    }
  }
}