@import "../../assets/style/mixin";

.services-sec{
  background: #716F70;
  padding: 120px 0;
  @include screen-max-width-767{
    padding: 80px 0;
  }
  @include screen-max-width-576{
    padding: 60px 0;
  }
  h2{
    color: #ffffff;
  }
  .services-flex{
    margin-top: 30px;
    position: relative;
    padding: 20px 0;
    @include screen-max-width-1200{
      flex-direction: column;
      gap: 50px;
      padding: 0;
    }
    &:before{
        content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 100%;
      background: #ffffff;
      @include screen-max-width-1200{
        display: none;
      }
    }
    .services-info{
      width: 46%;
      @include screen-max-width-1200{
        width: 100%;
      }
      h4{
        display: flex;
        align-items: center;
        font-size: 32px;
        line-height: 48px;
        min-height: 65px;
        color: #ffffff;
        position: relative;
        padding-bottom: 25px;
        @include screen-max-width-1200{
          font-size: 26px;
          line-height: 38px;
        }
        @include screen-max-width-767{
          font-size: 22px;
          line-height: 32px;
        }
        @include screen-max-width-576{
          font-size: 20px;
          line-height: 30px;
        }
        &:before{
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 140px;
          height: 2px;
          background: #ffffff;
        }
      }
      p{
        font-size: 22px;
        line-height: 32px;
        margin-top: 20px;
        color: #ffffff;
        @include screen-max-width-1200{
          font-size: 20px;
        }
        @include screen-max-width-767{
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }
}