@import "../../assets/style/mixin";


.about-sec {
  margin-top: 150px;
  margin-bottom: 120px;
  @include screen-max-width-991{
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @include screen-max-width-576{
    margin-top: 80px;
    margin-bottom: 60px;
  }
  .about-block {
    display: flex;
    flex-direction: column;
    gap: 60px;
    @include screen-max-width-767{
      gap: 40px;
    }
    .about-info {
      width: 100%;

      p {
        margin-top: 25px;
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        color: #000000;
        @include screen-max-width-991{
          font-size: 20px;
        }
        @include screen-max-width-767{
          font-size: 18px;
          line-height: 26px;
          margin-top: 15px;
        }
        @include screen-max-width-576{
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .about-video {
      width: 100%;
      border-radius: 10px;
      height: 700px;
      display: flex;
      @include screen-max-width-991{
        height: 550px;
      }
      @include screen-max-width-767{
        height: 500px;
      }
      video {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }
}

