@import "../../assets/style/mixin";


.map-sec{
  display: flex;
  width: 100%;
  iframe{
    width: 100%;
    height: 650px;
    @include screen-max-width-576{
      height: 500px;
    }
  }
}