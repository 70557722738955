@import "../../assets/style/mixin";


.information-sec{
  margin-top: 100px;
  .information-photo{
    display: flex;
    width: 100%;
    margin-top: 40px;
    @include screen-max-width-767{
      margin-top: 25px;
    }
    img{
      width: 100%;
      border-radius: 10px;
      @include screen-max-width-767{
        height: 400px;
        object-fit: cover;
      }
    }
  }
}