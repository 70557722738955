@import "../../assets/style/mixin";



.project-section{
  margin-top: 120px;
  @include screen-max-width-767{
    margin-top: 80px;
  }
  .project-cnt{
    margin-top: 40px;
    @include screen-max-width-767{
      margin-top: 20px;
    }
    gap: 20px;
    .project-box{
      width: calc(33.333333% - 15px);
      border-radius: 10px;
      padding: 15px 15px 25px;
      border: 2px solid #716F70;
      @include screen-max-width-1200{
        width: calc(50% - 15px);

      }
      @include screen-max-width-767{
        width: 100%;

      }
      .project-photo{
        display: flex;
        width: 100%;
        img{
          width: 100%;
          border-radius: 10px;
        }
      }
      h4{
        font-size: 24px;
        line-height: 32px;
        color: #413d3d;
        margin-top: 15px;
        text-transform: uppercase;
        @include screen-max-width-767{
          font-size: 22px;
        }
      }
    }
  }
}