@import "src/assets/style/variables";
@import "src/assets/style/mixin";


header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  transition: 0.3s;
  background: #343434cc;
  @include screen-max-width-991{
    position: absolute;
  }
  .header-main {
    padding: 10px 0;
    transition: 0.3s;
    .header-logo {
      display: flex;
      max-width: 75px;
      width: 100%;
    @include screen-max-width-576{
      max-width: 60px;
    }
      img {
        width: 100%;
        height: 100%;
      }

    }

    .header-menu {
      @include screen-max-width-991 {
        position: fixed;
        width: 300px;
        top: 0;
        right: 0;
        background-color: $black;
        height: 100vh;
        z-index: 111;
        padding: 80px 20px 20px;
        transform: translateX(300px);
        transition: 0.3s;

        &.open-menu {
          transform: translateX(0);
        }
      }

      ul {
        gap: 40px;
        @include screen-max-width-1399 {
          gap: 20px;
        }

        @include screen-max-width-991 {
          flex-direction: column;
          align-items: flex-end;
          gap: 30px;
        }

        li {
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 500;
          transition: 0.3s;
          color: $white;
          cursor: pointer;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 1px;
            background-color: $white;
            transition: 0.3s;
          }

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background-color: $white;
            transition: 0.3s;
          }

          &:hover {
            &::before {
              width: 100%;
            }

            &::after {
              width: 100%;
            }
          }

          @include screen-max-width-1399 {
            font-size: 18px;
          }
          @include screen-max-width-1399 {
            font-size: 16px;
          }
        }
      }

    }

    .header-lang {
      gap: 30px;

      @include screen-max-width-991 {
        gap: 20px;
      }

      .header-phone {
        width: 24px;
        display: flex;

        img {
          width: 100%;
        }
      }

      ul {
        gap: 30px;
        @include screen-max-width-991 {
          gap: 10px;
        }

        li {
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 500;
          transition: 0.3s;
          cursor: pointer;
          color: $white;
          line-height: 20px;
          @include screen-max-width-767 {
            font-size: 18px;
          }
        }
      }
    }

    .header-bg {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 10;
      transition: 0.3s;

      &.active-bg {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}




.toggle-menu {
  display: none;
  width: 30px;
  height: 14px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 999;

  @include screen-max-width-991 {
    display: flex;
  }


  span {
    width: 100%;
    height: 2px;
    background-color: $white;
    display: block;
    transition: 0.3s;

  }

  &.open-burger {
    justify-content: center;
    align-items: center;

    span {
      &:first-child {
        transform: rotate(45deg) translateX(2px);
      }

      &:last-child {
        transform: rotate(-45deg) translateX(1px);
      }
    }
  }
}

.mobile-languages {
  display: flex;
  margin-bottom: 40px;
  flex-direction: row !important;
  justify-content: flex-end;
}


.header-lang-item {
  position: relative;
  padding-bottom: 4px;
  &:after{
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0 !important;
    top: auto !important;
    right: 0;
    width: 0;
    height: 1px;
    background-color: $white;
    transition: 0.3s;
  }

  &.active:before {
    width: 100% !important;
  }
}
