@import "src/assets/style/variables";
@import "src/assets/style/mixin";


footer{
  background: #716F70;

  .footer-main{
    padding: 30px 0 20px;
    @include screen-max-width-576{
      flex-direction: column;
      gap: 30px;
    }
    .footer-logo{
      display: flex;
      width: 75px;
    }

    .footer-contact{
      gap: 30px;
      @include screen-max-width-576{
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        justify-content: center;
      }
      .icon-soc{
          width: 35px;
      }
      a{
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        transition: 0.3s;
        border-bottom: 1px solid transparent;
        display: flex;
        align-items: center;
        gap: 8px;
        span{
          border-bottom: 1px solid transparent;
          transition: 0.3s;

          &:hover{
            border-bottom: 1px solid #ffffff;
          }
        }
      }

    }
  }

  .header-photo{
    @include screen-max-width-576{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-down{
    padding-bottom: 20px;
    border-top: 1px solid #ffffff;
    padding-top: 20px;
    p{
      color: #ffffff;
      font-size: 18px;
      text-align: center;
    }
  }
}
