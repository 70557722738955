@import "../../assets/style/mixin";

.banner-sec{
  width: 100%;
  height: 100vh;
  position: relative;
  .banner-video{
    position: relative;
    width: 100%;
    height: 100%;
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
    }
  }
  .banner-main{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1;

      .banner-info{
        @include screen-max-width-1200{
          max-width: 700px;
          width: 100%;
        }
        h1{
          font-size: 50px;
          text-transform: uppercase;
          line-height: 60px;
          color: #ffffff;
          @include screen-max-width-1200{
            font-size: 44px;
            line-height: 54px;
          }
          @include screen-max-width-576{
            font-size: 28px;
            line-height: 36px;
          }
        }
        p{
          max-width: 800px;
          width: 100%;
          font-size: 32px;
          color: #ffffff;
          line-height: 37px;
          margin-top: 40px;
          @include screen-max-width-1200{
            font-size: 24px;
            line-height: 36px;
          }
          @include screen-max-width-576{
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
  }
}